<template>
  <div>
    <s-dialog :open="true" to="preview-early-access-dialog" size="md">
      <s-dialog-overlay />
      <s-dialog-panel class="!w-[93.2rem]">
        <st-dialog-header class="!pb-16" @clickClose="$emit('close')">
          <s-text as="span" role="title8" class="!font-bold">
            {{ $t('studio.prj_prod.this_prod.home_early_access_preview_title') }}
          </s-text>
        </st-dialog-header>
        <s-dialog-content
          class="dark flex flex-col items-center h-full bg-surface-variant-1 py-24 !px-32 !m-0"
        >
          <s-text as="p" role="text3" class="font-medium text-tint-red300">
            {{ $t('studio.prj_prod.this_prod.home_early_access_preview_guide1') }}
          </s-text>
          <s-text as="p" role="text4" class="text-on-surface-elevation-4">
            {{ $t('studio.prj_prod.this_prod.home_early_access_preview_guide2') }}
          </s-text>
          <button class="flex gap-8 items-center mt-12 cursor-default">
            <s-text as="p" role="btn2" class="text-on-surface-elevation-3">
              {{ $t('studio.prj_prod.this_prod.home_early_access_preview_see_creator_comment') }}
            </s-text>
            <s-icon
              icon="ic-v2-control-arrow-up-line"
              size="xl"
              class="text-on-surface-elevation-3"
            />
          </button>
          <div
            class="w-[57.2rem] h-[41.5rem] rounded-lg border-solid border-1 mt-20 overflow-x-hidden studio-scrollbar-4 border-black/6 dark:border-white/6"
          >
            <text-editor
              v-model="content"
              :toolbarButtons="[]"
              :initializedEditor="[setReadonly]"
              :config="editorConfig"
              class="no-border -mr-[1.3rem]"
            />
          </div>
        </s-dialog-content>
        <s-dialog-footer>
          <s-button class="min-w-264" @click="closeDialog">
            {{ $t('studio.common.popup_case_cf_btn') }}
          </s-button>
        </s-dialog-footer>
      </s-dialog-panel>
    </s-dialog>
    <s-portal-target name="preview-early-access-dialog" />
  </div>
</template>

<script lang="ts" setup>
import { toRefs } from 'vue';

import StDialogHeader from '@/components/common/st-dialog-header.vue';
import TextEditor from '@/components/validation/text-editor.vue';
import { closeDialog } from '@/composables/useDialog';

const props = defineProps<{
  content: string;
}>();

defineEmits<{
  close: [v?: boolean];
}>();

const { content } = toRefs(props);

const editorConfig = {
  attribution: false,
  charCounterCount: false,
  wordCounterCount: false,
  placeholderText: ''
};

const setReadonly = (editor: any) => {
  editor.edit.off();
  editor.toolbar.disable();
};
</script>
