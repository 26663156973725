import { useRequest } from '@/composables/useRequest';
import { ApiMethod } from '@/enums/api.enum';
import type { EarlyAccessRequest } from '@/types/early-access/early-access-request.type';
import type { EarlyAccessDetails } from '@/types/early-access/early-access-response.type';
import { camelToSnake } from '@/utils/api.util';

// https://wiki.smilegate.net/display/SDKAPIDOCU/WDB03.+studio+%7C+Early-Access+-+GET+Early+Access
export const fetchEarlyAccess = async (
  request: EarlyAccessRequest
): Promise<EarlyAccessDetails | undefined> => {
  const res = await useRequest<EarlyAccessDetails>(
    `studio/v1/product/${request.productNo}/early-access`,
    {
      showCommonError: false
    }
  );

  return res?.data;
};

// https://wiki.smilegate.net/display/SDKAPIDOCU/WDB03.+studio+%7C+Early-Access+-+POST++Early+Access
export const createEarlyAccess = async (request: EarlyAccessDetails): Promise<void> => {
  await useRequest<void>(`studio/v1/product/${request.productNo}/early-access`, {
    method: ApiMethod.Post,
    data: camelToSnake(request)
  });
};

// https://wiki.smilegate.net/display/SDKAPIDOCU/WDB03.+studio+%7C+Early-Access+-+PUT++Early+Access
export const updateEarlyAccess = async (request: EarlyAccessDetails): Promise<void> => {
  await useRequest<void>(`studio/v1/product/${request.productNo}/early-access`, {
    method: ApiMethod.Put,
    data: camelToSnake(request)
  });
};
